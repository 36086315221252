import React from 'react'
import FooterList from '../FooterList';
import footerlogo from "../../../assets/images/tgc-logo.svg";
import { Link } from 'react-router-dom';
import facebook from "../../../assets/images/Bg remove/facebook.png";
import twitter from "../../../assets/images/Bg remove/twitter.png";
import linkedin from "../../../assets/images/Bg remove/linkedin.png";
import insta from "../../../assets/images/Bg remove/insta.png";
import youtube from "../../../assets/images/Bg remove/youtube.png";
import phoneCall from "../../../assets/images/phone-call 1.png";
import email from "../../../assets/images/mail 1.png";
import webpage from "../../../assets/images/website 3.png";
import navigation from "../../../assets/images/gps-navigation 1.png";
import LetUSCallPopoverFooter from '../../Popover/LetUsCallPopoverFooter';
import england from "../../../assets/images/england.png";
import netherland from "../../../assets/images/netherland.png";
import "./footer.css";
import { useTranslation } from 'react-i18next';


const Footer = () => {
    const { t, i18n } = useTranslation();
    const currentYear = new Date().getFullYear();
    const languages = [
        { code: "en", codeName: "English" },
        { code: "nl", codeName: "Dutch" }
    ]
    return (
        <footer>
            <div className='footer-main'>
                <div className='container'>
                    <Link to="/">
                        <div className='footer-logo'>
                            <img src={footerlogo} alt="footer-logo" />
                        </div>
                    </Link>
                    <div className='footer-sec d-flex flex-wrap justify-content-between'>
                        <div className='foot-content-para' data-aos="fade-up" data-aos-duration="3000">
                            <h6>
                                <span><img src={navigation} alt='logo' /></span>
                                Weena 505 3013 AL Rotterdam
                            </h6>
                            <p>
                                <span><img src={email} alt='logo' /></span> <a href='mailto:Info@thegrowthcapital.nl'>Info@thegrowthcapital.nl</a>
                            </p>
                            <p>
                                <span><img src={phoneCall} alt='logo' /></span><a href='tel:+31 10 800 54 30'>+31 10 800 54 30</a>
                            </p>
                            <h4>
                                <span><img src={webpage} alt='logo' /></span>{t("registrationTitle")}
                            </h4>
                            <h5 className='px-4'>
                                86489283
                            </h5>
                        </div>
                        <div className='footer-content'>
                            <FooterList
                                foothead={t("headNavigationInvest")}
                                footlink1={t("headerWorkTitle")}
                                footlink2={t("headerFeatureTitle")}
                                footlink3={t("headerDirectTitle")}
                                footlink4={t("headerCompanyTitle")}
                                footurl1="/howitworks"
                                footurl4="/principles"
                                footurl3="/funds"
                                footurl2="/benefit"
                            />
                            <div className='foot-popover'>
                                <LetUSCallPopoverFooter />
                            </div>
                        </div>
                        <div className='footer-content'>
                            <FooterList
                                foothead={t("headNavigationCompany")}
                                footlink1={t("headerAboutTitle")}
                                footlink2={t("headerAfmTitle")}
                                // footlink4={t("headerLatestTitle")}
                                footlink3={t("headerContactTitle")}
                                footlink4={t("headerFoundationTitle")}
                                footurl1="/about"
                                footurl2="/afmregisteration"
                                footurl6="#"
                                footurl4="/foundation"
                                // footurl4="/latestnews"
                                footurl3="/contact"
                            />
                        </div>
                    </div>
                    <div className='switch-lang'>
                        <label onClick={() => i18n.changeLanguage(languages[0].code)}>
                            <img src={england} alt="footer flag" /><span className='switch-right'>{languages[0].codeName}</span>
                        </label>
                        <label onClick={() => i18n.changeLanguage(languages[1].code)}>
                            <img src={netherland} alt="footer flag" /><span>{languages[1].codeName}</span>
                        </label>
                    </div>
                    <div className='footer-bottom py-4 d-flex justify-content-between flex-wrap'>
                        <div className='bottom-content'>
                            <p>
                                © {currentYear} The Growth Capital. All rights reserved.
                            </p>
                        </div>
                        <div className='bottom-content text-center'>
                            <p>
                                <Link to="/policy">Privacy Policy</Link>
                            </p>
                        </div>
                        <div className='bottom-content text-center'>
                            <p>
                                <Link to="/termuse">Term of Use</Link>
                            </p>
                        </div>
                        <div className='foot-bottom-icon bottom-content'>
                            <ul className='d-flex'>
                                <li className='footinsta-logo'><a href="https://twitter.com/tgcinvest/" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="footer_icon" /></a></li>
                                {/* <li><a href="https://twitter.com/tgcinvest/" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="footer_icon" /></a></li> */}
                                <li className='footinsta-logo'><a href="https://www.linkedin.com/company/thegrowthcapital/" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="footer_icon" /></a></li>
                                <li className='footinsta-logo'><a href="https://www.instagram.com/thegrowthcapitalnl/" target="_blank" rel="noopener noreferrer"><img src={insta} alt="footer_icon" /></a></li>
                                <li className='footinsta-logo'><a href="https://www.facebook.com/TheGrowthCapital/" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="footer_icon" /></a></li>
                                <li className='footinsta-logo'>
                                    <a href="https://www.youtube.com/@thegrowthcapitaleng" target="_blank" rel="noopener noreferrer">
                                        <img src={youtube} alt="footer_icon" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer;